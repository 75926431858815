<template>
  <div class="mask-hot-wrap">
    <div class="pt20 video-img-demo">
      <el-card shadow="always" class="video-card">
        <p class="intro-title mb20">{{ video.title }}</p>

        <video-viewer
          class="mt20"
          width="100%"
          height="200px"
          :title="video.title"
          :cover="video.cover"
          :url="video.url"
        ></video-viewer>
      </el-card>

      <el-card shadow="always" class="video-card">
        <p class="intro-title mb20">满印布料</p>
        <el-image
          class="img-demo"
          fit="cover"
          src="http://39.99.237.105/resource/第三页满印介绍及案例展示/满印布料.jpg"
          @click="
            showPic(
              'http://39.99.237.105/resource/第三页满印介绍及案例展示/满印布料.jpg'
            )
          "
        ></el-image>
      </el-card>

      <el-card shadow="always" class="video-card">
        <p class="intro-title mb20">成人设计尺寸</p>
        <el-image
          class="img-demo"
          fit="cover"
          src="http://39.99.237.105/resource/第三页满印介绍及案例展示/成人设计尺寸.jpg"
          @click="
            showPic(
              'http://39.99.237.105/resource/第三页满印介绍及案例展示/成人设计尺寸.jpg'
            )
          "
        ></el-image>
      </el-card>

      <el-card shadow="always" class="video-card">
        <p class="intro-title mb20">儿童设计尺寸</p>
        <el-image
          class="img-demo"
          fit="cover"
          src="http://39.99.237.105/resource/第三页满印介绍及案例展示/儿童设计尺寸.jpg"
          @click="
            showPic(
              'http://39.99.237.105/resource/第三页满印介绍及案例展示/儿童设计尺寸.jpg'
            )
          "
        ></el-image>
      </el-card>
    </div>

    <div class="module-wrap">
      <div class="module-container">
        <div class="module-title">案例展示</div>
        <div class="module-body">
          <el-table :data="tableData" border stripe style="width: 100%">
            <el-table-column
              width="80px"
              align="center"
              type="index"
              :index="indexMethod"
            >
            </el-table-column>
            <el-table-column align="center" label="设计图">
              <template slot-scope="scope">
                <img
                  class="table-img-demo"
                  @click="showPic(scope.row.url0)"
                  v-lazy="scope.row.url0"
                  alt=""
                />
              </template>
            </el-table-column>

            <el-table-column align="center" label="效果图">
              <template slot-scope="scope">
                <img
                  class="table-img-demo"
                  @click="showPic(scope.row.url1)"
                  v-lazy="scope.row.url1"
                  alt=""
                />
              </template>
            </el-table-column>

            <el-table-column align="center" label="实物图">
              <template slot-scope="scope">
                <img
                  class="table-img-demo"
                  @click="showPic(scope.row.url2)"
                  v-lazy="scope.row.url2"
                  alt=""
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <transition name="el-fade-in-linear">
      <el-image-viewer
        v-if="preview"
        :url-list="[currentPic]"
        :on-close="
          () => {
            preview = false;
          }
        "
      />
    </transition>
  </div>
</template>

<script>
import VideoViewer from '@/components/VideoViewer';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

export default {
  data() {
    return {
      preview: false,
      currentPic: '',
      video: {
        title: '满印口罩介绍',
        cover: require('@/assets/images/video_cover5.png'),
        url:
          'http://39.99.237.105/resource/第三页满印介绍及案例展示/满印介绍.mp4',
      },
      tableData: [
        {
          url0:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例一/设计图.jpg',
          url1:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例一/效果图.jpg',
          url2:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例一/实物图.jpg',
        },
        {
          url0:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例二/设计图.jpg',
          url1:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例二/效果图.jpg',
          url2:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例二/实物图.jpg',
        },
        {
          url0:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例三/设计图.jpg',
          url1:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例三/效果图.jpg',
          url2:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例三/实物图.jpg',
        },
        {
          url0:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例四/设计图.jpg',
          url1:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例四/效果图.jpg',
          url2:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例四/实物图.jpg',
        },
        {
          url0:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例五/设计图.jpg',
          url1:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例五/效果图.jpg',
          url2:
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/案例五/实物图.jpg',
        },
      ],
    };
  },
  components: {
    VideoViewer,
    ElImageViewer,
  },
  computed: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  methods: {
    showPic(url) {
      this.preview = true;
      this.currentPic = url;
    },
    indexMethod(index) {
      const IndexMap = [
        '一',
        '二',
        '三',
        '四',
        '五',
        '六',
        '七',
        '八',
        '九',
        '十',
      ];
      return '案例' + IndexMap[index];
    },
  },
};
</script>

<style lang="less" scoped>
// /deep/ .el-image-viewer__wrapper .el-icon-circle-close {
//   color: #fff !important;
// }

.mask-hot-wrap {
  min-height: calc(100vh - 80px);

  .video-img-demo {
    max-width: @mainContainerWidth;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .img-demo {
      width: 100%;
      height: 200px;
      border-radius: 6px;

      &:hover {
        cursor: zoom-in;
      }
    }
  }

  .video-card {
    width: calc(25% - 20px);

    .intro-title {
      .title-24;
      text-align: center;
    }
  }

  .module-wrap {
    background-color: #fff;
    .module-container {
      padding-bottom: 40px;

      .module-title {
        background-image: linear-gradient(to bottom, #f7f7f7, #fff);

        .title-24;
        font-size: 30px;
        padding: 20px 0;
        text-align: center;
      }

      .module-body {
        max-width: @mainContainerWidth;
        margin: 0 auto;

        .table-img-demo {
          height: 100px;
          border-radius: 6px;
          border: 1px solid #eee;
          background-color: #fff;

          &:hover {
            cursor: zoom-in;
          }
        }
      }
    }
  }
}

// 移动端
@media screen and (max-width: 768px) {
  .intro-title,
  .module-title {
    font-size: 18px !important;
  }

  .video-card {
    width: 100% !important;

    & + .video-card {
      margin-top: 20px;
    }
  }

  .table-img-demo {
    height: 50px !important;
  }

  .video-img-demo {
    flex-direction: column;
  }
}
</style>
