var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mask-hot-wrap"},[_c('div',{staticClass:"pt20 video-img-demo"},[_c('el-card',{staticClass:"video-card",attrs:{"shadow":"always"}},[_c('p',{staticClass:"intro-title mb20"},[_vm._v(_vm._s(_vm.video.title))]),_c('video-viewer',{staticClass:"mt20",attrs:{"width":"100%","height":"200px","title":_vm.video.title,"cover":_vm.video.cover,"url":_vm.video.url}})],1),_c('el-card',{staticClass:"video-card",attrs:{"shadow":"always"}},[_c('p',{staticClass:"intro-title mb20"},[_vm._v("满印布料")]),_c('el-image',{staticClass:"img-demo",attrs:{"fit":"cover","src":"http://39.99.237.105/resource/第三页满印介绍及案例展示/满印布料.jpg"},on:{"click":function($event){return _vm.showPic(
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/满印布料.jpg'
          )}}})],1),_c('el-card',{staticClass:"video-card",attrs:{"shadow":"always"}},[_c('p',{staticClass:"intro-title mb20"},[_vm._v("成人设计尺寸")]),_c('el-image',{staticClass:"img-demo",attrs:{"fit":"cover","src":"http://39.99.237.105/resource/第三页满印介绍及案例展示/成人设计尺寸.jpg"},on:{"click":function($event){return _vm.showPic(
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/成人设计尺寸.jpg'
          )}}})],1),_c('el-card',{staticClass:"video-card",attrs:{"shadow":"always"}},[_c('p',{staticClass:"intro-title mb20"},[_vm._v("儿童设计尺寸")]),_c('el-image',{staticClass:"img-demo",attrs:{"fit":"cover","src":"http://39.99.237.105/resource/第三页满印介绍及案例展示/儿童设计尺寸.jpg"},on:{"click":function($event){return _vm.showPic(
            'http://39.99.237.105/resource/第三页满印介绍及案例展示/儿童设计尺寸.jpg'
          )}}})],1)],1),_c('div',{staticClass:"module-wrap"},[_c('div',{staticClass:"module-container"},[_c('div',{staticClass:"module-title"},[_vm._v("案例展示")]),_c('div',{staticClass:"module-body"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"width":"80px","align":"center","type":"index","index":_vm.indexMethod}}),_c('el-table-column',{attrs:{"align":"center","label":"设计图"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(scope.row.url0),expression:"scope.row.url0"}],staticClass:"table-img-demo",attrs:{"alt":""},on:{"click":function($event){return _vm.showPic(scope.row.url0)}}})]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"效果图"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(scope.row.url1),expression:"scope.row.url1"}],staticClass:"table-img-demo",attrs:{"alt":""},on:{"click":function($event){return _vm.showPic(scope.row.url1)}}})]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"实物图"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(scope.row.url2),expression:"scope.row.url2"}],staticClass:"table-img-demo",attrs:{"alt":""},on:{"click":function($event){return _vm.showPic(scope.row.url2)}}})]}}])})],1)],1)])]),_c('transition',{attrs:{"name":"el-fade-in-linear"}},[(_vm.preview)?_c('el-image-viewer',{attrs:{"url-list":[_vm.currentPic],"on-close":function () {
          _vm.preview = false;
        }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }